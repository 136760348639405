import React, {useEffect} from 'react';
import ItemOrder from "./itemOrder/itemOrder";
import styles from './itemList.module.css'
import Headers from "../../../shared/cart/headers";

function ItemsList({items}) {
    const [totalPrice, setTotalPrice] = React.useState(0);
    useEffect(() => {
        const total = items.reduce((accumulator, item) => {
            return accumulator + parseFloat(item.selected_price) * item.quantity;
        }, 0);

        setTotalPrice(total);
    }, [items]);


    return (
        <div className={styles.order_list}>
            {items.map((item, index) => (
                <ItemOrder item={item} index={index} key={index}/>
            ))}
            <div className={styles.total_price}>Итоговая стоимость: <span>{totalPrice}р.</span></div>
        </div>
    );
}

export default ItemsList;