import React, {useContext, useState} from 'react';
import styles from './cart.module.css'
import './../shared_styles.css'
import CartList from "./cartList";
import DeliveryChoose from "./deliveryChoose";
import Payment from "./payment";
import {Modal} from 'antd';
import ContactPerson from "./contactPerson";
import {CartContext} from "../../CardContext";
import axios from "axios";
import {useLocation, useNavigate} from 'react-router-dom';
import {ORDER_ENDPOINT} from "../../../ENDPOINTS";
import {Helmet} from "react-helmet";


const Cart = () => {
    const location = useLocation();
    const canonicalUrl = `https://technomir.by${location.pathname}`

    const {cartItems, clearCart} = useContext(CartContext);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [delivery, setDelivery] = useState("")
    const [payment, setPayment] = useState("")
    const [orderId, setOrderId] = useState(0)

    const navigate = useNavigate();

    const phoneRegex = /^(\+375\s?\(?\d{2}\)?\s?\d{3}-?\d{2}-?\d{2}|\+375\s?\d{2}\s?\d{7}|\+375\s?\d{2}\s?\d{3}\s?\d{2}\s?\d{2}|\+375\d{9}|\d{9})$/;

    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const {name, value} = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'comment':
                setComment(value);
                break;
            default:
                break;
        }
    };


    const handleDeliveryClick = (delivery) => {
        setDelivery(prevDelivery => prevDelivery === delivery ? "" : delivery);
    };

    const handlePaymentClick = (payment) => {
        setPayment(prevPayment => prevPayment === payment ? "" : payment);
    };

    const successOrder = () => {
        clearCart()
        setName('')
        setAddress('')
        setPhone('')
        setComment('')
        setDelivery("")
        setPayment('')
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        let csrfToken
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch (error) {
            console.error('Ошибка при создании заказа:', error);

        }

        const newErrors = {};
        if (!name) newErrors.name = 'Имя обязательно';
        if (!address) newErrors.address = 'Адрес обязателен';
        if (!phone) newErrors.phone = 'Телефон обязателен';
        else if (!phoneRegex.test(phone)) newErrors.phone = 'Телефон должен быть в формате +375XXXXXXXXX';
        if (!delivery) newErrors.delivery = 'Необходимо выбрать способ доставки'
        if (!payment) newErrors.payment = 'Необходимо выбрать способ оплаты'
        if (cartItems.length < 1) newErrors.cartItems = 'Вы ничего не добавили в корзину.'


        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
        }


        const orderData = {
            customer_name: name,
            phone_number: phone,
            address: address,
            delivery_note: comment,
            delivery: delivery,
            payment: payment,
            items: cartItems,
            status: 'active',
        };

        if (Object.keys(newErrors).length < 1) {
            try {
                const response = await axios.post(ORDER_ENDPOINT, orderData, {
                    headers: {
                        'X-CSRFToken': csrfToken
                    }
                });
                setOrderId(response.data.id);
                handleOpen()
                successOrder()

            } catch (error) {
                console.error('Ошибка при создании заказа:', error);

            }
        }
    };


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        navigate(`/order/${orderId}`);
    };

    return (
        <div>
            <Helmet>
                <link rel="canonical" href={canonicalUrl}/>
                <title>Оформление заказа в магазине Техномир: ваша корзина</title>
                <meta
                    name="description"
                    content="
                    Мы стремимся предоставить нашим клиентам лучший сервис и поддержку. В магазине «Техномир» вы можете легко оформить заказ, добавив товары в свою корзину. Мы гарантируем быструю и надёжную доставку, а также доступные цены на всю продукцию.
Посетите наш магазин и сделайте свой выбор прямо сейчас!
"
                />
            </Helmet>
            <div className={`common_mg ${styles.cart}`}>
                <CartList/>
            </div>

            <div className={`common_mg ${styles.cart} ${errors.delivery && styles.error_cart}`}>
                <DeliveryChoose handleDeliveryClick={handleDeliveryClick} delivery={delivery} errors={errors}/>
            </div>

            <div className={`common_mg ${styles.cart} ${errors.payment && styles.error_cart}`}>
                <Payment handlePaymentClick={handlePaymentClick} payment={payment} errors={errors}/>
            </div>

            <div
                className={`common_mg ${styles.cart} ${(errors.name || errors.address || errors.phone) && styles.error_cart}`}>
                <ContactPerson handleSubmit={handleSubmit} handleChange={handleChange} name={name} address={address}
                               phone={phone} comment={comment} errors={errors}/>
            </div>


            <Modal className={styles.modal}
                   centered
                   title="Спасибо за заказ!"
                   okText="Ок"
                   cancelText='Закрыть'
                   open={open}
                   onOk={handleClose}
                   onCancel={handleClose}

            >

                <div className={styles.modal_content}>После получения заказа наши менеджеры свяжутся с Вами для
                    уточнения всех деталей и подтверждения заказа.
                </div>
            </Modal>
        </div>
    );
};

export default Cart;