import React from 'react';
import styles from './cart.module.css'
import './../shared_styles.css'
import img_empty_cart from "../static/empty-cart.png";
import {Link} from "react-router-dom";

function EmptyCard(props) {
    return (
            <div className={styles.empty_cart}>
                <img src={img_empty_cart} alt=""/>
                <span>Ваша корзина пуста</span>
                <Link to="/catalog" className={styles.empty_cart_return_btn}>За покупками!</Link>
            </div>
    );
}

export default EmptyCard;