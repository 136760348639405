import React from 'react';
import styles from './contacts.module.css';
import '../../shared/shared_styles.css'
import Rekviz from "../about/Rekviz";
import img_tel from '../../shared/static/phone-call.png'
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const Contacts = React.memo((props) => {
    const location = useLocation();
    const canonicalUrl = `https://technomir.by${location.pathname}`

    return (
        <div className={styles.contacts + ' common_mg'}>
            <Helmet>
                <link rel="canonical" href={canonicalUrl}/>
                <title>Контактная информация магазина Техномир</title>
                <meta
                    name="description"
                    content="
                    В магазине «Техномир» мы стремимся сделать процесс покупки максимально удобным для вас. Если у вас возникли вопросы или вам нужна дополнительная информация, вы можете связаться с нами по указанным контактам. Мы всегда готовы помочь!
                    "
                    />
            </Helmet>
            <h1>Контакты</h1>
            <div className={styles.contacts_rows}>
                <div className={styles.contacts_row}>
                    <div className={styles.contacts_row_header}>Режим работы:</div>
                    <div className={styles.contacts_row_content}>
                        <div className={styles.contacts_row_content_days}>
                            <div className={styles.contacts_row_content_day}>Пн</div>
                            <div className={styles.contacts_row_content_day}>Вт</div>
                            <div className={styles.contacts_row_content_day}>Ср</div>
                            <div className={styles.contacts_row_content_day}>Чт</div>
                            <div className={styles.contacts_row_content_day}>Пт</div>
                            <div className={styles.contacts_row_content_day}>Сб</div>
                            <div className={styles.contacts_row_content_day}>Вс</div>
                        </div>
                        <div className={styles.contacts_row_content_text}>
                            Работаем для вас каждый день с 9:00 до 21:00
                        </div>
                    </div>
                </div>
                <div className={styles.contacts_row_divider}></div>
                <div className={styles.contacts_row} >
                    <div className={styles.contacts_row_header}>Телефоны:</div>
                    <div className={styles.contacts_row_content}>
                        <div className={styles.contacts_row_content_telephone}>
                            <img src={img_tel} alt=""/>
                            <div className={styles.contacts_row_content_telephone_text}><a href="tel:+375 (29) 810-17-94">+375 (29) 810-17-94</a></div>
                        </div>
                        <div className={styles.contacts_row_content_telephone}>
                            <img src={img_tel} alt=""/>
                            <div className={styles.contacts_row_content_telephone_text}><a href="tel:+375 (33) 719-83-25">+375 (33) 719-83-25</a></div>
                        </div>
                    </div>
                </div>
                <div className={styles.contacts_row_divider}></div>
                <div className={styles.contacts_row}>
                    <div className={styles.contacts_row_header}>Реквизиты:</div>
                    <div className={styles.contacts_row_content}>
                        <Rekviz/>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default Contacts;