import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {useLocation, useParams} from 'react-router-dom';
import styles from './product.module.css';
import {SMARTPHONES_ENDPOINT} from "../../../ENDPOINTS";
import ColorsOption from "./colorsOption";
import StorageOption from "./storageOption";
import ProductDetail from "./productDetail";
import {CartContext} from "../../CardContext";
import AlertSuccess from "../../shared/catalog_component/Item/alert_success";
import CarouselImages from "./carousel_images";
import gif_loading from '../../shared/static/loading.gif'
import img_error from '../../shared/static/error.png'
import {Helmet} from "react-helmet";

const Product = () => {

    const {id} = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [images, setImages] = useState([]);
    const [mainImage, setMainImage] = useState("");
    const [color, setColor] = useState({'color': ''});
    const [storage, setStorage] = useState({'storage': ''});
    const [alertVisible, setAlertVisible] = useState(false);
    const [price, setPrice] = useState(0)
    const {addToCart} = useContext(CartContext);

    const handleAddToCart = () => {
        const updatedProduct = {
            ...product,
            selected_color: color || null,
            selected_storage: storage || null,
            selected_price: price
        };

        addToCart(updatedProduct, 1);
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 3000);
    };

    const details = product ? [
        {label: 'Категория', value: ['Смартфоны', 'Смарт-Часы', 'Наушники'][product.category-1]},
        {label: 'Операционная система', value: ['Ios', 'Android'][product.os-1]},
        {label: 'Производитель', value: ['Apple', 'Samsung'][product.brand-1]},
        {label: 'Дата выпуска', value: product.release_date},
        {label: 'Модель', value: product.model},
        {label: 'Разрешение экрана', value: product.screen_resolution},
        {label: 'Размер экрана', value: product.screen_size},
        {label: 'Технология экрана', value: product.screen_technology},
        {label: 'Частота обновления', value: product.refresh_rate},
        {label: 'Оперативная память', value: product.ram},
        {label: 'Аккумулятор', value: product.battery},
        {label: 'Камера', value: product.camera},
        {label: 'Количество основных камер', value: product.main_cameras_count},

        {label: 'Процессор', value: product.processor},
        {label: 'Количество SIM-карт', value: product.sim_cards_count},
        {label: 'Материал корпуса', value: product.body_material},
        {label: 'Аксессуары', value: product.accessories},
    ] : [];


    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${SMARTPHONES_ENDPOINT}${id}`);
                setProduct(response.data);
                setImages(response.data.images || []);
                setMainImage(response.data.images[0] || '')
                setPrice(response.data.price || 'Нет данных')
            } catch (err) {setError(err);
            } finally {setLoading(false)}};
        fetchProduct();
    }, [id]);


    const handleChangeMainImg = (image) => {
        setMainImage(image);

    };
    const location = useLocation();
    const canonicalUrl = `https://technomir.by${location.pathname}`
    return (
        <div className={`${styles.products} common_mg`}>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>{loading ? "Загрузка..." : `Купить ${product.name.split(' ').length > 4 ? product.name.split(' ').slice(0, 4).join(' ') : product.name} в Магазине Техномир`}</title>
                <meta
                    name="description"
                    content={loading ? "Загрузка..." : `Ищете {product.name}? В Техномир вы найдете его по лучшим ценам с гарантией качества. Быстрая доставка и уникальные предложения. Закажите сейчас!`}
                />
            </Helmet>
            {(loading) ?
                <div className={styles.loading}>
                    <img src={gif_loading} alt=""/>
                </div>
                : product && (
                <div className={styles.product}>
                    <Helmet>
                        <title>Купить {product.name.split(' ').length > 4 ? product.name.split(' ').slice(0, 4).join(' ') : product.name} в Магазине Техномир - Ваш идеальный выбор</title>
                    </Helmet>
                    <div className={styles.product_header}>
                        <CarouselImages
                            product={product}
                            images={images}
                            mainImage={mainImage}
                            handleChangeMainImg={handleChangeMainImg}
                        />


                        <div className={styles.product_header_content}>
                            <div className={styles.header_name}><h1>{product.name}</h1></div>

                            <div className={styles.header_descr}>{product.description}</div>
                            <div className={styles.header_options}>
                                <div className={styles.header_option}>
                                    <ColorsOption product={product} setColor={setColor} color={color}
                                                  images={images} setImages={setImages}
                                                  handleChangeMainImg={handleChangeMainImg}
                                    setPrice={setPrice}/>
                                </div>
                                <div className={styles.header_option}>
                                    <StorageOption product={product} storage={storage} setStorage={setStorage}
                                    setPrice={setPrice}/>
                                </div>
                            </div>
                            <div className={styles.product_price}>{price} р.</div>
                            <div className={styles.product_buy_btn} onClick={handleAddToCart}>В коризну</div>
                        </div>
                    </div>

                    <div className={styles.product_content}>
                        <ul>
                            <h1>Характеристики</h1>
                            {(product && details.map((item, index) => (
                                (item.value !== 0 && item.value !== 'Нет') &&
                                (<li key={index}>
                                    <ProductDetail label={item.label} value={item.value}/>
                                </li>)

                            )))}


                        </ul>
                    </div>
                </div>
            )}
            <AlertSuccess show={alertVisible} type='success' text='Товар был успешно добавлен в корзину'/>

            {(error || (!error && !loading && product ===[])) &&
                <div className={styles.error}>
                    <img src={img_error} alt=""/>
                    <div className={styles.error_msg}>Страница не найдена.</div>
                </div>
            }
        </div>
    );
};

export default Product;