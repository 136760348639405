import React from 'react';
import styles from './carousel.module.css';
import {Carousel} from "antd";
import '../../../shared/shared_styles.css'
import video_1 from './../../../shared/static/xlarge (2).gif'
import video_2 from './../../../shared/static/mac.gif'
import video_3 from './../../../shared/static/xlarge.gif'
import { useInView } from 'react-intersection-observer';

const LazyLoadGif = ({ src }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,  // Чтобы загружать только один раз
        threshold: 0.5,  // Загружаем, когда 50% элемента на экране
    });

    return (
        <div ref={ref} className={styles.carousel_img}>
            {inView ? (
                <img src={src} alt={'Loading'} />
            ) : (
                <div>Загружаем...</div> // Placeholder, пока изображение не загружено
            )}
        </div>
    );
};

const CarouselComponent = React.memo((props) => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Чтобы загружать только один раз
        threshold: 0.5, // Загружаем, когда 50% элемента на экране
    });
    return (
        <Carousel className={"common_mg " + styles.carousel_main} arrows dotPosition="bottom" draggable={true}>
            <div>
                <div className={styles.carousel}>
                    <div className={styles.carousel_img}>
                        <LazyLoadGif src={video_1}/>

                        {/*<video height='100%' width='100%' controls autoPlay loop muted playsinline >*/}
                        {/*    <source src={video_1}/>*/}
                        {/*</video>*/}
                        <div className={styles.carousel_conteiner}>
                            <div className={styles.carousel_block}>
                                <div className={styles.carousel_header}>iPhone – Воплощение инноваций</div>
                                <div className={styles.carousel_text}>Испытайте мощь и стиль нового уровня. Откройте для
                                    себя лучшие технологии, созданные для вас.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className={styles.carousel}>
                    <div className={styles.carousel_img}>
                        <LazyLoadGif src={video_2}/>
                        {/*<video height='100%' width='100%' controls autoPlay loop muted playsinline>*/}
                        {/*    <source src={video_2}/>*/}
                        {/*</video>*/}
                        <div className={styles.carousel_conteiner}>
                            <div className={styles.carousel_block}>
                                <div className={styles.carousel_header}>iMac – искусство технологий на вашем рабочем
                                    столе
                                </div>
                                <div className={styles.carousel_text}>Оцените мощь и стиль в одном устройстве. Закажите
                                    iMac в нашем интернет-магазине с быстрой доставкой.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className={styles.carousel}>
                    <div className={styles.carousel_img}>
                        <LazyLoadGif src={video_3}/>
                        {/*<video height='100%' width='100%' controls autoPlay loop muted playsinline>*/}
                        {/*    <source src={video_3}/>*/}
                        {/*</video>*/}
                        <div className={styles.carousel_conteiner}>
                            <div className={styles.carousel_block}>
                                <div className={styles.carousel_header}>Смартфон, который вам нужен</div>
                                <div className={styles.carousel_text}>Закажите у нас и получите лучшее обслуживание.
                                    Удобный онлайн-заказ и быстрая доставка.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </Carousel>
    );
})

export default CarouselComponent;