import React from 'react';

import styles from "./cart.module.css";
import './../shared_styles.css'


function DeliveryChoose({handleDeliveryClick, delivery, errors}) {

    return (
        <div>
            <h1>Выберете способ доставки</h1>

            <div className={styles.divider}></div>
            <div className={styles.delivery_choose}>
                <div onClick={()=>handleDeliveryClick('По Минску')} className={`${styles.delivery_option} ${delivery==='По Минску' && styles.option_active}`}>
                    <div className={styles.delivery_option_header}>Доставка товара по Минску</div>
                    <div className={styles.delivery_option_text}>Доставка товара курьером.</div>
                </div>
                <div onClick={()=>handleDeliveryClick('По Респеблике')} className={`${styles.delivery_option} ${delivery==='По Респеблике' && styles.option_active}`}>
                    <div className={styles.delivery_option_header}>Доставка по городам Беларуси</div>
                    <div className={styles.delivery_option_text}>При заказе выбранного Вами товара в нашем магазине
                        доставка производится курьерской службой. Платно в зависимости от выбранного товара
                    </div>
                </div>

            </div>
            {errors.delivery && <div className={`${styles.error} ${styles.error_header}`}>{errors.delivery}</div>}
        </div>
    );
}

export default DeliveryChoose;