import React from 'react';
import styles from './about.module.css'
import '../../shared/shared_styles.css'

function Rekviz(props) {
    return (
        <div className={styles.about_OOORec}>
            <div className={styles.about_OOORec_content}>
                <div className={styles.about_OOORec_text}>
                    ООО "Даиманд Техника" <br/>

                    Юридический адрес:  Беларусь, 223039 Минская область, Минский район, Хатежинский сельсовет, аг. Хатежино, ул. Центральная, дом 18Б, корп. 1, пом. 47 <br/>
                    Почтовый адрес: РБ, 223039, Минская область, Минский район, с/с Хатежинский, аг. Хатежино, ул. Центральная, д. 18Б <br/>

                    УНП 193681968 <br/>
                    Дата регистрации в Торговом реестре РБ: 05.08.2024 <br/>
                    bytechnomir@gmail.com
                </div>
            </div>

        </div>
    );
}

export default Rekviz;
