import React from 'react';
import '../../../shared/shared_styles.css'
import styles from './menuItems.module.css'
import Order from "./Order";

function Erip(props) {
    return (
        <div>
            <div className={styles.menuItem_Header}>Оплата через ЕРИП</div>
            <div className={styles.menuItem_Text}>
                <div>
                    Для вашего удобства мы предоставляем возможность оплатить заказ через систему ЕРИП. Этот метод
                    оплаты прост, безопасен и доступен для всех пользователей.
                </div>
                <div>
                    Сделайте заказ на нашем сайте, выберите способ оплаты через ЕРИП, и после подтверждения заказа вам
                    будет предоставлен уникальный код для оплаты. Вы сможете легко провести оплату через
                    интернет-банкинг, мобильное приложение или терминал самообслуживания, выбрав нашу компанию в дереве
                    ЕРИП.
                </div>
            </div>
            <Order/>
        </div>
    );
}

export default Erip;