import React from 'react';
import '../../../shared/shared_styles.css'
import styles from './menuItems.module.css'
import Order from "./Order";

function Card(props) {
    return (
        <div>
            <div className={styles.menuItem_Header}>Оплата картой</div>
            <div className={styles.menuItem_Text}>
                <div>
                    Мы предлагаем удобный и безопасный способ оплаты картой при получении заказа.
                </div>
                <div>
                    Сделайте покупку на нашем сайте, выберите удобное время доставки, и при получении своих новых
                    гаджетов вы сможете оплатить их с помощью банковской карты.
                </div>
                <div>
                    Этот способ оплаты обеспечивает простоту и безопасность — просто воспользуйтесь своим терминалом,
                    чтобы
                    завершить покупку. Оплата картой позволяет избежать необходимости иметь при себе наличные и делает
                    процесс покупки ещё более комфортным.
                </div>

            </div>
            <div className={styles.menuItem_SubText}>                Мы стремимся предоставить вам максимальное удобство и заботимся о вашей безопасности на каждом этапе
                покупки!</div>
            <Order/>
        </div>
    );
}

export default Card;