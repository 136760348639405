import './App.css';
import Routing from "./components/routing";
import {CartProvider} from "./components/CardContext";


function App() {
    return (
        <div>
            <CartProvider>
            <Routing/>
            </CartProvider>


        </div>
    );
}

export default App;
