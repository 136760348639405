// import React from 'react';
// import styles from './filter.module.css';
// import {Radio} from "antd";
//
// function Filter({filterName,filterList, filterValue, handleFilterChange}) {
//     return (
//         <div className={styles.filter}>
//             <div className={styles.filters_header}>{filterName}</div>
//             <Radio.Group
//                 onChange={handleFilterChange}
//                 style={{display: 'flex', flexDirection: 'column', overflowY:'auto', height:'100px'}}
//                 value={filterValue}
//             >
//                 <Radio value=""><span className={styles.filter_name}>Все {filterName}</span></Radio>
//                 {filterList.length > 0 && filterList.map(filter => (
//                     <Radio key={filter.id} value={filter.id}>
//                         {filter.name}
//                     </Radio>
//                 ))}
//             </Radio.Group>
//         </div>
//     );
// }
//
// export default Filter;

import React from 'react';
import styles from './filter.module.css';
import { Select } from 'antd';

const { Option } = Select;

function Filter({ filterName, filterList, filterValue, handleFilterChange }) {
    return (
        <div className={styles.filter}>
            <div className={styles.filters_header}>{filterName}</div>
            <Select
                value={filterValue}
                onChange={handleFilterChange}
                placeholder={`Выберите ${filterName}`}
            >
                <Option value=""><span className={styles.filter_name}>Все {filterName}</span></Option>
                {filterList.length > 0 && filterList.map(filter => (
                    <Option key={filter.id} value={filter.id}>
                        {filter.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
}

export default Filter;
