import React from 'react';
import styles from './footer.module.css';
import '../shared_styles.css'
import {Link} from "react-router-dom";
import logo from './../static/logo.png';
import LazyLoad from "react-lazyload";

function Footer(props) {
    return (
        <div className={'common_pg header_footer ' + styles.footer}>
            <div className={styles.footer_info}>
                <div className={styles.footer_info_row}>

                    <div className={styles.logo}>
                        <LazyLoad height={200} once>
                            <img src={logo} alt=""/>
                        </LazyLoad>
                    </div>

                    <div className={styles.footer_info_row_content}>
                        <div className={styles.footer_info_OOO}>ООО "Даиманд Техника"</div>
                        <div className={styles.footer_info_OOO}>УНП 193681968</div>
                        <div className={styles.footer_info_OOO}>223039 Беларусь, Минская область, Минский район,
                            Хатежинский сельсовет, аг. Хатежино, ул. Центральная, дом 18Б, корп. 1, пом. 47
                        </div>
                        <div className={styles.footer_info_OOO}>Свидетельство о государственной регистрации 193681968 от
                            07.04.2023 <br/> выдано Минским Горисполкомом.
                        </div>


                        <div className={styles.footer_info_OOO}>Дата регистрации в Торговом реестре РБ: 05.08.2024</div>
                        <div className={styles.footer_info_OOO}> Рассмотрение обращений потребителей, телефон
                            +375298101794 <br/>
                            bytechnomir@gmail.com
                        </div>

                    </div>
                </div>


                <div className={styles.footer_info_row}>
                    <div className={styles.footer_info_row_header}>Информация</div>
                    <div className={styles.footer_info_row_content}>
                        <Link to="/">Главная</Link>
                        <Link to="/catalog">Каталог</Link>
                        <Link to="/delivery">Доставка и оплата</Link>
                        <Link to="/about">О нас</Link>
                        <Link to="/contact">Контакты</Link>
                    </div>
                </div>
                <div className={styles.footer_info_row}>
                    <div className={styles.footer_info_row_header}>Контакты</div>
                    <div className={styles.footer_info_row_content}>

                        <div className={styles.footer_info_contacts}>
                            <div className={styles.footer_info_contact}><a href="tel:+375 (29) 810-17-94">+375 (29)
                                810-17-94</a></div>
                            <div className={styles.footer_info_contact}><a href="tel:+375 (33) 719-83-25">+375 (33)
                                719-83-25</a></div>
                        </div>
                        <div className={styles.footer_info_working_time}>Режим работы: <br/>ежедневно c 09:00 до 21:00
                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.footer_copyright}></div>
        </div>
    );
}

export default Footer;