import React from 'react';
import styles from './advantages.module.css'
import '../../../shared/shared_styles.css'
import {Carousel} from "antd";
import stars from '../../../shared/static/rating.png'
import quote from '../../../shared/static/quote.png'
import LazyLoad from "react-lazyload";

const Advantages = React.memo((props) => {
    return (
        <div className={'common_mg ' + styles.advantages}>
            <h1>Отзывы клиентов</h1>
            <Carousel autoplay arrows dotPosition="left" draggable={true}>
                <div className={styles.advantages_item}>
                    <LazyLoad height={200} once>
                        <img className={styles.advantages_item_quote} src={quote} alt=""/>
                    </LazyLoad>
                    <div className={styles.advantages_item_content}>
                        <div className={styles.advantages_item_author}>
                            <div className={styles.advantages_item_author_stars}>
                                <LazyLoad height={100} once>
                                    <img src={stars} alt=""/>
                                </LazyLoad>
                            </div>
                            <div className={styles.advantages_item_author_name}>Платонов Михаил</div>
                            <div className={styles.advantages_item_author_data}>14:23 05.08.2024</div>
                        </div>
                        <div className={styles.advantages_item_content_header}>
                            <div>Идеальный выбор для тех, кто ценит сервис!</div>
                        </div>
                        <div className={styles.advantages_item_content_text}>
                            Оформление заказа прошло гладко, а доставка была быстрой. Упаковка была качественной, и
                            телефон пришел в целости. Соответствие описанию на сайте полностью оправдано. Без нареканий.
                        </div>
                    </div>
                </div>

                <div className={styles.advantages_item}>
                    <LazyLoad height={200} once>
                        <img className={styles.advantages_item_quote} src={quote} alt=""/>
                    </LazyLoad>
                    <div className={styles.advantages_item_content}>
                        <div className={styles.advantages_item_author}>
                            <div className={styles.advantages_item_author_stars}>
                                <LazyLoad height={100} once>
                                    <img src={stars} alt=""/>
                                </LazyLoad></div>
                            <div className={styles.advantages_item_author_name}>Казаков Даниил</div>
                            <div className={styles.advantages_item_author_data}>12:34 08.08.2024</div>
                        </div>
                        <div className={styles.advantages_item_content_header}>
                            <div>Отличный сервис и оригинальная продукция!</div>
                        </div>
                        <div className={styles.advantages_item_content_text}>
                            Смартфон оригинальный, с гарантией и по разумной цене. Быстрая доставка и качественная
                            упаковка тоже порадовали. Магазин оправдал все ожидания!
                        </div>
                    </div>
                </div>

                <div className={styles.advantages_item}>
                    <LazyLoad height={200} once>
                        <img className={styles.advantages_item_quote} src={quote} alt=""/>
                    </LazyLoad>
                    <div className={styles.advantages_item_content}>
                        <div className={styles.advantages_item_author}>
                            <div className={styles.advantages_item_author_stars}>
                                <LazyLoad height={100} once>
                                    <img src={stars} alt=""/>
                                </LazyLoad>
                            </div>
                            <div className={styles.advantages_item_author_name}>Лаврова Вероника</div>
                            <div className={styles.advantages_item_author_data}>21:46 19.06.2024</div>
                        </div>
                        <div className={styles.advantages_item_content_header}>
                            <div>Хороший опыт покупки</div>
                        </div>
                        <div className={styles.advantages_item_content_text}>
                            Купила смартфон в ТехноМире. Процесс покупки был понятным, сайт удобный. Смартфон доставили
                            без задержек, никаких нареканий по качеству товара. Магазин выполнил свои обязательства, как
                            и обещали.
                        </div>
                    </div>
                </div>

                <div className={styles.advantages_item}>
                    <LazyLoad height={200} once>
                        <img className={styles.advantages_item_quote} src={quote} alt=""/>
                    </LazyLoad>
                    <div className={styles.advantages_item_content}>
                        <div className={styles.advantages_item_author}>
                            <div className={styles.advantages_item_author_stars}>
                                <LazyLoad height={100} once>
                                    <img src={stars} alt=""/>
                                </LazyLoad>
                            </div>
                            <div className={styles.advantages_item_author_name}>Озеров Кирилл</div>
                            <div className={styles.advantages_item_author_data}>19:51 16.07.2024</div>
                        </div>
                        <div className={styles.advantages_item_content_header}>
                            <div>Быстрая доставка и отличные цены!</div>
                        </div>
                        <div className={styles.advantages_item_content_text}>
                            Заказывал новый смартфон, доставили быстро, упаковка без повреждений. Цены отличные, а
                            качество обслуживания на высоте. Обязательно вернусь за покупками!
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
})

export default Advantages;