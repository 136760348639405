import React from 'react';
import styles from "../../shared/catalog_component/catalog_component.module.css";


function StorageOption({product, storage, setStorage, setPrice}) {

    const handleStorageClick = (clicked_storage) => {
        if (storage === clicked_storage) {
            setStorage({'storage': ''});
            setPrice(product.price)
        } else {
            setStorage(clicked_storage);
            setPrice(clicked_storage.price)
        }
    };
    return (
        <div className={styles.card_colors_container}>
            {product.storage && product.storage.length > 0 &&
                <div className={styles.card_storages}>
                    <div className={styles.color_header}>{product.category === 2 ? 'Размер:': 'Память:'}</div>
                    {product.storage.map((product_storage, index) => (
                        <div onClick={() => handleStorageClick(product_storage)} key={index}
                             className={`${styles.card_storage} ${storage.storage === product_storage.storage ? styles.storage_selected : ''}`}>{product_storage.storage}</div>
                    ))}
                </div>
            }
            {storage.storage !== '' &&
                <div className={styles.card_color_name}> {storage.storage}</div>}
        </div>
    );
}

export default StorageOption;