import React from 'react';
import {Divider} from "antd";
import '../../../shared/shared_styles.css'
import styles from './reviews.module.css'
import fd_icon from '../../../shared/static/free-delivery (1).png'
import lp_icon from '../../../shared/static/low-price (1).png'
import o_icon from '../../../shared/static/original (1).png'
import g_icon from '../../../shared/static/gift (1).png'
import LazyLoad from 'react-lazyload';

const Reviews = React.memo((props) => {
    return (
        <div className={'common_mg ' + styles.reviews}>
            <Divider orientation="left" orientationMargin={30}><h1>Наши преимущества</h1></Divider>
            <div className={styles.reviews_container}>
                <div className={styles.reviews_container_item}>
                    <div className={styles.reviews_container_item_img}>
                        <LazyLoad height={200} once>
                            <img src={fd_icon} alt=""/>
                        </LazyLoad>
                    </div>
                    <div className={styles.reviews_container_item_content}>
                    <div className={styles.reviews_container_item_header}>Бесплатная доставка</div>
                    <div className={styles.reviews_container_item_text}>Мы доставим ваш заказ быстро и бесплатно, чтобы
                        вы могли насладиться вашим новым устройством без лишних хлопот и дополнительных расходов.
                    </div></div>
                </div>
                <div className={styles.reviews_container_divider}></div>
                <div className={styles.reviews_container_item}>
                    <div className={styles.reviews_container_item_img}>
                        <LazyLoad height={200} once>
                            <img src={lp_icon} alt=""/>
                        </LazyLoad>
                    </div>
                    <div className={styles.reviews_container_item_content}>
                    <div className={styles.reviews_container_item_header}>Низкие цены</div>
                    <div className={styles.reviews_container_item_text}>С нами вы можете
                        быть уверены, что получите отличное качество по лучшей цене!
                    </div></div>
                </div>
                <div className={styles.reviews_container_divider}></div>
                <div className={styles.reviews_container_item}>
                    <div className={styles.reviews_container_item_img}>
                        <LazyLoad height={200} once>
                            <img src={o_icon} alt=""/>
                        </LazyLoad>
                    </div>
                    <div className={styles.reviews_container_item_content}>
                    <div className={styles.reviews_container_item_header}>Оригинальная продукция</div>
                    <div className={styles.reviews_container_item_text}>В нашем магазине вы найдете только оригинальные
                        смартфоны от официальных поставщиков.
                    </div></div>
                </div>
                <div className={styles.reviews_container_divider}></div>
                <div className={styles.reviews_container_item}>
                    <div className={styles.reviews_container_item_img}>
                        <LazyLoad height={200} once>
                            <img src={g_icon} alt=""/>
                        </LazyLoad>
                    </div>
                    <div className={styles.reviews_container_item_content}>
                    <div className={styles.reviews_container_item_header}>Гарантия</div>
                    <div className={styles.reviews_container_item_text}>Каждый смартфон в ... сопровождается
                        гарантией, которая дает вам уверенность в надежности покупки.
                    </div></div>
                </div>
            </div>
        </div>
    );
})

export default Reviews;