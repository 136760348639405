import React from 'react';
import styles from "./product.module.css";
import {Image} from 'antd';

function CarouselImages({images, mainImage, handleChangeMainImg, product}) {

    return (
        <div className={styles.carousel}>

            <div className={styles.bugets_list}>
                {product.is_new && <div className={styles.bugets_list_new}><span>Новинка</span></div>}
                {product.is_popular && <div className={styles.bugets_list_hit}><span>Хит продаж</span></div>}
            </div>


            <div className={styles.main_img}>
                {(mainImage && <Image
                    src={mainImage}
                    preview={{
                        mask: null, // Убирает текст "Preview"
                    }}
                />)}
            </div>
            <div className={styles.other_imgs}>
                {images.length > 0 && images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index}`}
                        className={styles.thumbnail}
                        onClick={() => handleChangeMainImg(image)}
                    />
                ))}
            </div>

        </div>

    );
}

export default CarouselImages;