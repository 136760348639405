import React from 'react';
import styles from "./cart.module.css";
import './../shared_styles.css'

function Payment({handlePaymentClick, payment,errors}) {

    return (
        <div>
            <h1>Выбирите способ оплаты</h1>
            <div className={styles.divider}></div>
            <div className={styles.delivery_choose}>
                <div onClick={() => handlePaymentClick('Наличными')} className={`${styles.delivery_option} ${styles.payment_option} ${payment === 'Наличными' && styles.option_active}`}>
                    <div className={styles.delivery_option_header}>Оплата наличными</div>
                    <div className={styles.delivery_option_text}>Оплата производится наличными при получении товара.</div>
                </div>
                <div onClick={() => handlePaymentClick('Картой')} className={`${styles.delivery_option} ${styles.payment_option} ${payment === 'Картой' && styles.option_active}`}>
                    <div className={styles.delivery_option_header}>Оплата картой</div>
                    <div className={styles.delivery_option_text}>Оплата банковской картой при получении.
                    </div>
                </div>
                <div onClick={() => handlePaymentClick('ЕРИП')} className={`${styles.delivery_option} ${styles.payment_option} ${payment === 'ЕРИП' && styles.option_active}`}>
                    <div className={styles.delivery_option_header}>Оплата Ерип</div>
                    <div className={styles.delivery_option_text}>Оплата через систему ЕРИП с использованием любого удобного устройства.
                    </div>
                </div>

            </div>
            {errors.payment && <div className={`${styles.error} ${styles.error_header}`}>{errors.payment}</div>}
        </div>
    );
}

export default Payment;