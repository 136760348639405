import React from 'react';
import styles from './itemOrder.module.css';
import {Link} from "react-router-dom";

function ItemOrder({item, index}) {
    return (
        <div className={styles.item} key={index}>
            <div className={styles.item_name_container}>
                <div className={styles.item_name_img}><img src={item.images[0]} alt=""/></div>
                <div className={styles.item_name_block}>
                    <div className={styles.item_name}><Link to={`/product/${item.id}`}>{item.name}</Link></div>
                    <div className={styles.item_name_options}>{item.selected_storage.storage} {item.selected_color.color}</div>
                </div>
            </div>
            <div className={styles.item_price_container}>
                <div className={styles.item_price}><span>{item.quantity > 1 && `${item.quantity} x `}</span>{item.selected_price}р.</div>
            </div>
        </div>
    );
}

export default ItemOrder;