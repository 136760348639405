import React from 'react';
import '../../../shared/shared_styles.css'
import styles from './menuItems.module.css'
import img_chek from '../../../shared/static/kassovyj-chek.jpg'
import { Image } from 'antd';

function Order(props) {
    return (
        <div>
            <div className={styles.menuItem_Header}>Документация:</div>
            <div className={styles.menuItem_doc}>
                <div className={styles.menuItem_doc_img}>
                    <Image className={styles.menuItem_doc_img} width={200}
                           src={img_chek}/>
                    <div className={styles.menuItem_doc_img_description}>Чек оплаты</div>
                </div>
                {/*<div className={styles.menuItem_doc_img}>*/}
                {/*    <Image className={styles.menuItem_doc_img} width={200}*/}
                {/*           src={img_svid}/>*/}
                {/*    <div className={styles.menuItem_doc_img_description}>Сведетельство о регистрации</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Order;
