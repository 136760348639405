import React from 'react';
import styles from "./cart.module.css";
import './../shared_styles.css'


function Total(props) {

    return (
        <div className={styles.total}>

            <div onClick={props.submit} className={styles.order + ' ' + styles.empty_cart_return_btn}>Оформить заказ</div>
        </div>
    );
}

export default Total;