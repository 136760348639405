import React from 'react';
import styles from './cart.module.css'
import './../shared_styles.css'

function Headers(props) {
    return (
        <div className={styles.cart_list_headers}>
            <div className={styles.cart_list_name_header}><span>Товары</span></div>
            <div className={styles.cart_list_sub_headers}>
                <div className={`${styles.cart_list_sub_header} ${styles.cart_list_first_header}`}><span>Цена, Руб.</span></div>
                <div className={`${styles.cart_list_sub_header} ${styles.cart_list_sub_header_add}`}><span>Количество</span></div>
                <div className={`${styles.cart_list_sub_header} ${styles.cart_list_sub_header_add}`}><span>Сумма, Руб.</span></div>
            </div>
        </div>
    );
}

export default Headers;