import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import '../shared_styles.css'
import styles from './header.module.css'
import tel_icon from '../static/phone-call.png'
import time_icon from '../static/clock.png'
import SearchWithAutocomplete from "./autoSuggestion";
import {CartContext} from "../../CardContext";
import CartIcon from "./cart_icon";
import logo from './../static/logo.png'
import mini_logo from './../static/mini_logo.png'


function Header(props) {
    const {cartItems} = useContext(CartContext);
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const isAtBottom = (document.documentElement.scrollHeight - window.scrollY - window.innerHeight) > 100;
            if (window.scrollY > 100 && isAtBottom) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <div className={styles.header_main}>
            <div className={styles.header_links}>
                <div className={'common_pg header_footer ' + styles.header}>
                    <Link to="/">Главная</Link>
                    <Link to="/catalog">Каталог</Link>
                    <Link to="/delivery">Доставка</Link>
                    <Link to="/about">О нас</Link>
                    <Link to="/contact">Контакты</Link>
                </div>


            </div>
            <div className={'common_pg ' + styles.header_sec_row}>
                {/*<div><img className={styles.header_logo} src={img_logo} alt=""/></div>*/}
                <Link to='/'><div className={styles.logoTest}>
                    <img className={styles.logo_main} src={logo} alt=""/>
                    <img className={styles.logo_mini} src={mini_logo} alt=""/>
                </div></Link>
                <div className={styles.header_search_box}>
                    <SearchWithAutocomplete />
                </div>

                <CartIcon cartItems={cartItems} />

                <div className={styles.time_phone}>
                    <div className={styles.header_contact}>
                        <div><img className={styles.header_icons} src={tel_icon} alt=""/></div>
                        <div><a href="tel:+375 (29) 719-83-25">+375 (29) 719-83-25</a></div>
                    </div>
                    <div className={styles.time}>
                        <div><img className={styles.header_icons} src={time_icon} alt=""/></div>
                        <div>9:00-21:00 ежедневно</div>
                    </div>

                </div>
            </div>
            {location.pathname !== '/cart' && (
            <Link to='/cart/'>
            <div className={`${styles.cart_icon_floating} ${isVisible ? styles.cart_icon_show : ''}`}>
                <CartIcon cartItems={cartItems}/>
            </div>
            </Link>
            )}
        </div>
    );
}

export default Header;