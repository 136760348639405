import React, {useContext, useState} from 'react';
import styles from './cart.module.css'
import './../shared_styles.css'
import img_remove from "../static/remove.png";
import {CartContext} from "../../CardContext";
import {Link} from "react-router-dom";
import AlertSuccess from "../catalog_component/Item/alert_success";


function CartItem(props) {
    const {removeFromCart, reduceQuantityItem} = useContext(CartContext);
    const {addToCart} = useContext(CartContext);
    const [isExiting, setIsExiting] = useState(false);
    const handleRemove = () => {
        setIsExiting(true);
        setTimeout(() => {
            removeFromCart(props.item);
            setIsExiting(false);
        }, 700); // Длительность анимации
    };

    return (
        <div>
        <div className={`${styles.cart_list} ${styles.cart_item}`} style={{opacity: (isExiting ? 0 : 1), transform: (isExiting ? 'translateX(-100%)': 'translateX(0)')}} key={props.item.id}>
            <div className={styles.cart_list_headers + ' ' + styles.cart_list_item_headers}>
                <div
                    className={styles.cart_list_name_header + ' ' + styles.cart_list_item_name__headers}>
                    {props.item.selected_color && props.item.selected_color.color !== '' ?
                        <img src={props.item.selected_color.imgs[0]} alt=""/> :
                        <img src={props.item.images[0]} alt=""/>
                    }

                    <div className={styles.item_name_property}>
                        <div className={styles.item_name}><Link
                            to={`/product/${props.item.id}`}>{props.item.name}</Link></div>
                        <div
                            className={styles.item_property}>{props.item.selected_color && props.item.selected_color.color} {props.item.selected_storage && props.item.selected_storage.storage}</div>
                    </div>

                </div>
                <div className={styles.cart_list_sub_headers}>
                    <div className={styles.cart_adaptive}>
                        <div
                            className={styles.cart_list_sub_header + ' ' + styles.cart_list_item_sub_header + ' ' + styles.cart_adaptive_block}>{props.item.selected_price}р.
                        </div>
                        <div
                            className={styles.cart_list_sub_header + ' ' + styles.cart_list_item_sub_header + ' ' + styles.cart_adaptive_block}>
                            <div className={styles.item_quantity}>
                                <div onClick={() => props.item.quantity === 1 ? handleRemove() : reduceQuantityItem(props.item)}
                                     className={styles.item_quantity_btn}>-
                                </div>
                                <div className={styles.item_quantity_value}>{props.item.quantity}</div>
                                <div onClick={() => addToCart(props.item, 1)}
                                     className={styles.item_quantity_btn}>+
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={styles.cart_list_sub_header + ' ' + styles.cart_list_item_sub_header}
                        style={{fontWeight: '500'}}>{props.item.selected_price * props.item.quantity}р.
                    </div>
                </div>
                <div className={styles.item_remove} onClick={handleRemove}>
                    <img src={img_remove} alt=""/>
                </div>

            </div>
        </div>
        <AlertSuccess show={isExiting} type='error' text='Товар был удален из корзины'/>
        </div>
    );
}

export default CartItem;