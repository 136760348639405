import React from 'react';
import '../../../shared/shared_styles.css'
import styles from './menuItems.module.css'

function DeliveryMinsk(props) {
    return (
        <div>
            <div className={styles.menuItem_Header}>Доставка по Минску</div>
            <div className={styles.menuItem_Text}>Доставка осуществляется с 13:00 до 22:00. По согласованию с клиентом возможна более поздняя доставка.</div>
            <div className={styles.menuItem_Text}>Мы стремимся сделать процесс доставки максимально удобным и быстрым,
                чтобы вы могли наслаждаться своими новыми гаджетами в кратчайшие сроки. Вот как мы организуем доставку
                по Минску:
            </div>

            <div className={styles.menuItem_Header}>Условия доставки:</div>
            <ul className={styles.menuItem_List}>
                <li className={styles.menuItem_ListItem}><span className={styles.menuItem_ListItem_header}> Предварительное уведомление:</span> Наш
                    курьер обязательно свяжется с вами за 15-20 минут до прибытия, чтобы вы успели подготовиться к
                    встрече.
                </li>
                <li className={styles.menuItem_ListItem}><span className={styles.menuItem_ListItem_header}>Время ожидания: </span> Курьер
                    будет ожидать вас в течение 30 минут. Первые 15 минут включены в стоимость доставки, чтобы вы могли
                    спокойно забрать свой заказ.
                </li>
                <li className={styles.menuItem_ListItem}><span className={styles.menuItem_ListItem_header}>Сроки доставки:</span> Мы
                    доставляем ваши заказы в течение 1-3 дней с момента подтверждения заказа, обеспечивая оперативность
                    и надежность.
                </li>
                <li className={styles.menuItem_ListItem}><span className={styles.menuItem_ListItem_header}>Точка доставки:</span> Ваш
                    заказ будет доставлен прямо до вашей квартиры или ворот частного дома, чтобы вы могли быстро и
                    удобно получить свои гаджеты.
                </li>
            </ul>
            <div className={styles.menuItem_SubText}>Мы гордимся нашей оперативностью и вниманием к деталям, чтобы
                процесс получения вашего заказа был приятным и удобным. Выбирая нас, вы выбираете надёжность и комфорт!
            </div>

        </div>
    );
}

export default DeliveryMinsk;
