import React from 'react';
import '../../../shared/shared_styles.css'
import styles from './menuItems.module.css'
import Order from "./Order";

function Cash(props) {
    return (
        <div>
            <div className={styles.menuItem_Header}>Оплата наличными</div>
            <div className={styles.menuItem_Text}>
                <div>
                    Мы делаем процесс покупки ещё более удобным, предлагая вам возможность оплатить заказ наличными при
                    получении.
                </div>
                <div>
                    Просто сделайте заказ на нашем сайте, выберите удобное время доставки, и при получении ваших
                    новых гаджетов вы сможете оплатить покупку курьеру.
                </div>

                <div>
                    Этот способ оплаты позволяет вам лично убедиться в качестве и комплектности товара перед оплатой,
                    что делает процесс покупки максимально безопасным и прозрачным.
                </div>

            </div>
            <div className={styles.menuItem_SubText}> Мы ценим ваше доверие и стараемся сделать покупки в нашем магазине
                лёгким и приятным!
            </div>
            <Order/>
        </div>
    );
}

export default Cash;