import React, {useContext, useState} from 'react';
import styles from "../catalog_component.module.css";
import {CartContext} from '../../../CardContext';
import AlertSuccess from "./alert_success";
import {Tooltip} from "antd";
import {Link} from "react-router-dom";
import LazyLoad from 'react-lazyload';

function Item(product) {
    const {addToCart} = useContext(CartContext);
    const [alertVisible, setAlertVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState({'color': ''});
    const [selectedStorage, setSelectedStorage] = useState({'storage': ''});
    const [price, setPrice] = useState(product.price)

     const handleAddToCart = () => {
        const updatedProduct = {
            ...product,
            selected_color: selectedColor || null,
            selected_storage: selectedStorage || null,
            selected_price: price
        };
        addToCart(updatedProduct, 1);
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 3000);
    };

    const handleColorClick = (color) => {
        setSelectedColor(prevColor => prevColor === color ? {'color': ''} : color);
    };

    const handleStorageClick = (storage) => {
        if (selectedStorage === storage) {
            setSelectedStorage({'storage': ''});
            setPrice(product.price)
        } else {
            setSelectedStorage(storage);
            setPrice(storage.price)
        }
    };

    return (
        <div className={styles.card}>
            <div className={styles.bugets_list}>
                {product.is_new && <div className={styles.bugets_list_new}><span>Новинка</span></div>}
                {product.is_popular && <div className={styles.bugets_list_hit}><span>Хит продаж</span></div>}
            </div>
            <Link to={`/product/${product.id}`}>
                <LazyLoad height={200} once>
                    <div
                        className={styles.card_img}
                        style={{
                            backgroundImage: `url(${selectedColor.color === '' ? product.images[0] : selectedColor.imgs[0]})`,
                        }}
                    ></div>
                </LazyLoad>
            </Link>

            {/*<div className={styles.card_price}>{product.price}<span*/}
            {/*    style={{fontSize: '14px'}}>BYN</span></div>*/}


            <div className={styles.card_price}>{price}<span
                style={{fontSize: '14px', fontWeight: '400'}}>Руб.</span></div>


            <div className={styles.card_name}>
                <Link to={`/product/${product.id}`}>
                    <div className={styles.card_div_name}><span className={styles.card_span_name}>{product.name}</span>
                    </div>
                </Link>
                <div className={styles.card_properties}>


                </div>
            </div>


            <div className={styles.card_descr}>{product.description}</div>

            <div className={styles.card_colors_container}>
                {product.colors.length > 0 &&
                    <div className={styles.card_colors}>
                        {product.colors.map((color, index) => (
                            <Tooltip placement="top" title={color.color}
                                     color={['Белый', 'Серебристый', 'White', 'Awesome White', 'Cream', 'Phantom White', "Starlight"].includes(color.color) ? '#939393' : color.HEX}
                                     key={index}>
                                <div onClick={() => handleColorClick(color)}
                                     style={{backgroundColor: `${color.HEX}`}}
                                     className={`${styles.card_color} ${selectedColor.color === color.color ? styles.selected : ''}`}></div>
                            </Tooltip>
                        ))}

                    </div>
                }
                {selectedColor.color !== '' && <div className={styles.card_color_name}> {selectedColor.color}</div>}
            </div>

            <div className={styles.card_colors_container}>
                {product.storage && product.storage.length > 0 &&
                    <div className={styles.card_storages}>
                        {product.storage.map((storage, index) => (
                            <div onClick={() => handleStorageClick(storage)} key={index}
                                 className={`${styles.card_storage} ${selectedStorage.storage === storage.storage ? styles.storage_selected : ''}`}>{storage.storage}</div>
                        ))}
                    </div>
                }
                {selectedStorage.storage !== '' &&
                    <div className={styles.card_color_name}> {selectedStorage.storage}</div>}
            </div>

            <div className={styles.card_bts}>
                <div className={styles.card_btn} onClick={handleAddToCart}>В корзину</div>
            </div>
            <AlertSuccess show={alertVisible} type='success' text='Товар был успешно добавлен в корзину'/>
        </div>

    );
}

export default Item;