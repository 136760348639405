import React, {useEffect, useRef, useState} from 'react';
import '../shared_styles.css';
import styles from './catalog_component.module.css';
import axios from 'axios';
import {BRAND_ENDPOINT, CATEGORIES_ENDPOINT, SMARTPHONES_ENDPOINT, SYSTEM_ENDPOINT} from '../../../ENDPOINTS';
import {Card, Col, Pagination} from 'antd';
import Item from './Item/item.jsx';
import {useLocation} from 'react-router-dom';
import img_empty_catalog from './../static/empty_catalog.png';
import Filter from './filter/filter';
import Sorting from './sorting';
import {Helmet} from "react-helmet";

function CatalogComponent({default_brand, default_category}) {
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('');
    const [filters, setFilters] = useState({});
    const [brands, setBrands] = useState([]);
    const [operatingSystems, setOperatingSystems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brand, setBrand] = useState(default_brand);
    const [categorie, setCategorie] = useState(default_category);
    const [title, setTitle] = useState('Телефон');

    const listRef = useRef(null);

    const location = useLocation();
    const searchQuery = new URLSearchParams(location.search).get('search') || '';

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const offset = (currentPage - 1) * 12;
            const cacheKey = `products-${searchQuery}-${currentPage}-${sortOrder}-${JSON.stringify(filters)}`;

            const response = await axios.get(SMARTPHONES_ENDPOINT, {
                params: {
                    search: searchQuery,
                    ...filters,
                    ordering: sortOrder,
                    limit: 12,
                    offset: offset,
                },
            });

            setProducts(response.data.results);
            setTotal(response.data.count);

            localStorage.setItem(cacheKey, JSON.stringify(response.data));

        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const [brandsResponse, osResponse, categoriesResponse] = await Promise.all([
                    axios.get(BRAND_ENDPOINT),
                    axios.get(SYSTEM_ENDPOINT),
                    axios.get(CATEGORIES_ENDPOINT),
                ]);

                const brandsData = brandsResponse.data.results;
                const osData = osResponse.data.results;
                const categoriesData = categoriesResponse.data.results;

                setBrands(brandsData);
                setOperatingSystems(osData);
                setCategories(categoriesData);

                // Установка значений по умолчанию для brand и category
                if (default_brand) {
                    const foundBrand = brandsData.find(brand => brand.id === default_brand);
                    if (foundBrand) {
                        setBrand(foundBrand.id);
                        setTitle(foundBrand.name);
                        setFilters(prevFilters => ({ ...prevFilters, brand: foundBrand.id }));
                    }
                }

                if (default_category) {
                    const foundCategory = categoriesData.find(category => category.id === default_category);
                    if (foundCategory) {
                        setCategorie(foundCategory.id);
                        setTitle(foundCategory.name);
                        setFilters(prevFilters => ({ ...prevFilters, category: foundCategory.id }));
                    }
                }

            } catch (error) {
                console.error('Ошибка загрузки фильтров:', error);
            }
        };
        fetchFilters();
    }, [default_brand, default_category]);

    useEffect(() => {
        fetchProducts();
    }, [searchQuery, currentPage, sortOrder, filters]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        if (listRef.current) {
            listRef.current.scrollIntoView();
        }
    };

    const handleSortChange = (value) => {
        setSortOrder(value);
        handlePageChange(1);
    };

    const handleFilterChange = (value, key) => {
        setFilters(prevFilters => ({...prevFilters, [key]: value}));
        handlePageChange(1);
    };

    const handleBrandChange = (value) => {
        const is_element = brands.find(brand => brand.id === value)
        setBrand(value);
        if (is_element) {
            setTitle(brands.find(brand => brand.id === value).name)
        } else {
            setTitle('Телефон')
        }
        handleFilterChange(value, 'brand');
    };

    const handleCategoryChange = (value) => {
        const is_element = categories.find(category => category.id === value)
        setCategorie(value);
        if (is_element) {
            setTitle(categories.find(category => category.id === value).name)
        } else {
            setTitle('Телефон')
        }

        handleFilterChange(value, 'category');
    };


    return (
        <div ref={listRef} className={'common_mg ' + styles.catalog}>
            {loading ?
                <div className={styles.cards}>
                    <Col span={8}>
                        <Card loading={true} title="Загружаем товары..." bordered={false}></Card>
                    </Col>
                </div>
                :
                <div>
                    <h1>Каталог</h1>
                    {location.pathname.startsWith('/catalog') &&
                        <Helmet>
                            <title>Купить {`${title}`} в Минске. Интернет-магазин Техномир</title>
                            <meta name="description"
                                  content={`Откройте для себя широкий ассортимент товаров ${title !== 'Телефон' ? title : '' } в нашем каталоге. Качество, доступные цены и быстрая доставка. Найдите идеальный продукт для себя уже сегодня!`}/>
                        </Helmet>
                    }

                    <div className={styles.filters}>
                        <div className={styles.filters_filters}>
                            <Filter filterName='Категории' filterList={categories} filterValue={categorie}
                                    handleFilterChange={handleCategoryChange}/>
                            <Filter filterName='Производители' filterList={brands} filterValue={brand}
                                    handleFilterChange={handleBrandChange}/>
                        </div>
                        <div className={styles.filters_sort}>
                            <Sorting sortOrder={sortOrder} handleSortChange={handleSortChange}/>
                        </div>
                    </div>
                    <div className={styles.catalog_conteiner}>
                        <div className={styles.cards}>
                            {products.length > 0 ? products.map(product => (
                                    <Item
                                        key={product.id}
                                        id={product.id}
                                        images={product.images}
                                        price={product.price}
                                        name={product.name}
                                        description={product.description}
                                        is_new={product.is_new}
                                        is_popular={product.is_popular}
                                        old_price={product.old_price}
                                        brand={product.brand}
                                        colors={product.colors}
                                        release_year={product.release_year}
                                        storage={product.storage}
                                    />
                                )) :
                                <div className={styles.catalog_noItems}>
                                    <div><img src={img_empty_catalog} alt=""/></div>
                                    <div><span>Мы ничего не смогли найти по вашему запросу ...</span></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>}
            {total > 12 && <Pagination current={currentPage} defaultPageSize={12} align="center" total={total}
                                       onChange={handlePageChange} showSizeChanger={false}/>}
        </div>
    );
}

export default CatalogComponent;
