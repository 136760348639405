import React from 'react';
import CarouselComponent from "./carousel/carousel";
import CatalogComponent from "../../shared/catalog_component/catalog_component";
import Advantages from "./advantages/advantages";
import Reviews from "./reviews/reviews";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

function Main(props) {
    const location = useLocation();
    const canonicalUrl = `https://technomir.by${location.pathname}`
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>Интернет-магазин Техномир - магазин современных гаджетов</title>
                <meta name='description' content='В Техномир вы найдёте широкий ассортимент техники от ведущих брендов. Гарантия качества и лучшие цены. Посетите наш сайт и выберите свои технологии!'/>
            </Helmet>
            <CarouselComponent/>
            <CatalogComponent/>
            <Reviews/>
            <Advantages/>
        </div>
    );
}

export default Main;