import React from 'react';
import styles from './cart.module.css'
import './../shared_styles.css'
import Total from "./Total";

function ContactPerson({name, phone, address, comment, errors, handleChange, handleSubmit}) {
    return (
        <div className={styles.contactPerson}>
            <h1>Контактная информация</h1>

            <div className={styles.divider}></div>
            <div className={styles.personaForms}>
                <div className={styles.personaForm}>
                    <div className={styles.inputForms}>
                        <div className={styles.inputForm}>
                            <div className={styles.inputFormLable}>Имя*</div>
                            <div className={`${styles.inputFormInput} ${errors.name && styles.error_input}`}>
                                <input type="text"
                                       name="name"
                                       value={name}
                                       onChange={handleChange}/>
                                {errors.name && <div className={`${styles.error} ${styles.error_input_field}`}>{errors.name}</div>}
                            </div>
                        </div>
                        <div className={styles.inputForm}>
                            <div className={styles.inputFormLable}>Адрес*</div>
                            <div className={`${styles.inputFormInput} ${errors.address && styles.error_input}`}>
                                <input
                                    type="text"
                                    name="address"
                                    value={address}
                                    onChange={handleChange}
                                />
                                {errors.address && <div className={`${styles.error} ${styles.error_input_field}`}>{errors.address}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.personaForm}>
                    <div className={styles.inputForms}>
                        <div className={styles.inputForm}>
                            <div className={styles.inputFormLable}>Телефон*</div>
                            <div className={`${styles.inputFormInput} ${errors.phone && styles.error_input}`}>
                                <input
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    onChange={handleChange}
                                    placeholder='+ 375 (ХХ) ХХХ-ХХ-ХХ'
                                />
                                {errors.phone && <div className={`${styles.error} ${styles.error_input_field}`}>{errors.phone}</div>}
                            </div>
                        </div>
                        <div className={styles.inputForm}>
                            <div className={styles.inputFormLable}>Комментарий к заказу</div>
                            <div className={`${styles.inputFormInput} ${errors.comment && styles.error_input}`}>
                                <input
                                    type="text"
                                    name="comment"
                                    value={comment}
                                    onChange={handleChange}
                                    placeholder='Любые пожелания по доставке'
                                />
                                {errors.comment && <div className={`${styles.error} ${styles.error_input_field}`}>{errors.comment}</div>}
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            {errors.cartItems && <div className={`${styles.error} ${styles.error_header}`}>{errors.cartItems}</div>}
            <div className={styles.subText}>* - обязательные поля</div>
            <Total submit={handleSubmit}/>
            <div className={`${styles.subText} ${styles.subTextManager}`}>
                После получения заказа наши менеджеры свяжутся с Вами для уточнения всех деталей и подтверждения заказа.
            </div>
        </div>
    );
}

export default ContactPerson;