import React from 'react';
import styles from "./catalog_component.module.css";

function Sorting({sortOrder, handleSortChange}) {
    return (
        <div className={styles.filter_sort}>
            {/*<div className={styles.sorting_header}><span>Сортировка</span></div>*/}

            <div className={styles.sorting_select}>
                <div onClick={()=>handleSortChange('')} className={`${styles.sorting_option} ${sortOrder==='' && styles.sorting_option_selected}`}>По релевантности</div>
                <div className={styles.dividor}></div>
                <div onClick={()=>handleSortChange('price')} className={`${styles.sorting_option} ${sortOrder==='price' && styles.sorting_option_selected}`}>С дешевых</div>
                <div className={styles.dividor}></div>
                <div onClick={()=>handleSortChange('-price')} className={`${styles.sorting_option} ${sortOrder==='-price' && styles.sorting_option_selected}`}>С дорогих</div>
                <div className={styles.dividor}></div>
                <div onClick={()=>handleSortChange('-release_date')} className={`${styles.sorting_option} ${sortOrder==='-release_date' && styles.sorting_option_selected}`}>С новинок</div>
            </div>

        </div>
    );
}

export default Sorting;