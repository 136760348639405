import React from 'react';
import Alert from '@mui/material/Alert';

import styles from './item.module.css'


function AlertSuccess({show, type, text}) {
    return (
        <div className={styles.alert} style={{opacity: show ? 1 : 0, bottom: show ? '8px' : '-40px' }}>
            <Alert variant="filled" severity={type}>
                {text}
            </Alert>
        </div>
    );
}

export default AlertSuccess;
