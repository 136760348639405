// routing.jsx
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Catalog from "./pages/catalog/catalog";
import Contacts from "./pages/contacts/contacts";
import Delivery from "./pages/delivery/delivery";
import Product from "./pages/product/product";
import About from "./pages/about/about";
import Main from "./pages/main/main";
import './shared/shared_styles.css'
import ScrollToTop from "./ScrollToTop";
import Cart from "./shared/cart/cart";
import Order from "./pages/order/order";


const Routing = () => {
    return (
        <div className={'body_sv'}>


            <Router>
                <ScrollToTop/>
                <Header/>
                <div>

                    <Routes>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/delivery" element={<Delivery/>}/>
                        <Route path="/catalog" element={<Catalog brand={[]} category={[]}/>}/>
                        <Route path="/contact" element={<Contacts/>}/>
                        <Route path="/cart" element={<Cart />} />

                        <Route path="/catalog/Apple" element={<Catalog brand={1} category={[]}/>}/>
                        <Route path="/catalog/Samsung" element={<Catalog brand={2} category={[]}/>}/>
                        <Route path="/catalog/Smartphones" element={<Catalog brand={[]} category={1}/>}/>
                        <Route path="/catalog/Watches" element={<Catalog brand={[]} category={2}/>}/>
                        <Route path="/catalog/Headphones" element={<Catalog brand={[]} category={3}/>}/>
                        <Route path="/catalog/Tablets" element={<Catalog brand={[]} category={4}/>}/>
                        <Route path="/catalog/Notebooks" element={<Catalog brand={[]} category={7}/>}/>

                        <Route path="/order/:id" element={<Order />} />
                        <Route path="/product/:id" element={<Product />} />
                        <Route path="/" element={<Main/>}/>
                    </Routes>
                </div>
                <Footer/>

            </Router>

        </div>
    );
};

export default Routing;
