import React, {createContext, useEffect, useState} from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (product, quantity) => {
        setCartItems((prevItems) => {
            const existingItem = prevItems.find(item =>
                item.id === product.id &&
                item.selected_color === product.selected_color &&
                item.selected_price === product.selected_price &&
                item.selected_storage === product.selected_storage
            );

            if (existingItem) {
                return prevItems.map(item =>
                    item.id === product.id &&
                    item.selected_color === product.selected_color &&
                    item.selected_price === product.selected_price &&
                    item.selected_storage === product.selected_storage
                        ? { ...item, quantity: item.quantity + quantity }
                        : item
                );
            } else {
                return [...prevItems, { ...product, quantity }];
            }
        });
    };

    const removeFromCart = (product) => {
        setCartItems(prevItems => prevItems.filter(item =>
            !(item.id === product.id &&
                item.selected_color === product.selected_color &&
                item.selected_storage === product.selected_storage)
        ));
    };

    const reduceQuantityItem = (product) => {
        setCartItems((prevItems) => {
            // Найти элемент с совпадающими параметрами
            const existingItem = prevItems.find(item =>
                item.id === product.id &&
                item.selected_color === product.selected_color &&
                item.selected_storage === product.selected_storage
            );

            // Если элемент не найден, вернуть прежний список товаров
            if (!existingItem) return prevItems;

            // Если количество товара равно 1, удаляем его
            if (existingItem.quantity === 1) {
                return prevItems.filter(item =>
                    !(item.id === product.id &&
                        item.selected_color === product.selected_color &&
                        item.selected_storage === product.selected_storage)
                );
            } else {
                // Иначе уменьшаем количество на 1
                return prevItems.map(item =>
                    item.id === product.id &&
                    item.selected_color === product.selected_color &&
                    item.selected_storage === product.selected_storage
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                );
            }
        });
    };

    const clearCart = () => {
        setCartItems([]);
    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, reduceQuantityItem, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};