import React from 'react';
import styles from './product.module.css'

function ProductDetail({label, value}) {
    return (
        <div className={styles.detail}>
            <div className={styles.detail_name}>{label}</div>
            <div className={styles.detail_value}>{value}</div>
        </div>
    );
}

export default ProductDetail;