import React, {useEffect, useState} from 'react';
import styles from './order.module.css'
import './../../shared/shared_styles.css'
import {useParams} from "react-router-dom";
import axios from "axios";
import {ORDER_ENDPOINT} from "../../../ENDPOINTS";
import gif_loading from '../../shared/static/loading.gif'
import ItemsList from "./itemList/itemsList";
import {Helmet} from "react-helmet";

function Order(props) {
    const {id} = useParams();
    const [orderInfo, setOrderInfo] = useState(null)

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${ORDER_ENDPOINT}${id}`);
                setOrderInfo(response.data)
            } catch (err) {
                console.log(err)
            }
        };
        fetchProduct();
    }, [id]);

    if (orderInfo) {
        return (
            <div className={`${styles.order} common_pg common_mg`}>
                <Helmet>
                    <title>Оформление заказа в магазине Техномир: Подробности заказа</title>
                    />
                </Helmet>
                <h1 className={styles.title}><span>Заказ - № {id}</span></h1>
                <div>
                        <span className={styles.order_date}>
                            {orderInfo.date_created.slice(0, 10).split('-')[2]}.{orderInfo.date_created.slice(0, 10).split('-')[1]}.{orderInfo.date_created.slice(0, 10).split('-')[0]}
                        </span>
                </div>
                <div className={styles.order_block}>

                    <div className={styles.order_list}>
                        <div className={styles.order_number}><span>Корзина:</span></div>
                        <ItemsList items={orderInfo.items}/>
                    </div>
                    <div className={styles.order_divider}></div>
                    <div className={styles.order_container}>

                        <div className={styles.order_details_title}><span>Подробности заказа:</span></div>
                        <div className={styles.order_details}>
                            <div className={styles.order_detail_item}><span
                                className={styles.order_detail_name}>Имя:</span>
                                <span className={styles.order_detail_value}>{orderInfo.customer_name}</span></div>
                            <div className={styles.order_detail_item}><span
                                className={styles.order_detail_name}>Номер телефона:</span> <span
                                className={styles.order_detail_value}>{orderInfo.phone_number}</span></div>
                            <div className={styles.order_detail_item}><span
                                className={styles.order_detail_name}>Адрес:</span>
                                <span className={styles.order_detail_value}>{orderInfo.address}</span></div>
                            <div className={styles.order_detail_item}><span
                                className={styles.order_detail_name}>Доставка:</span> <span
                                className={styles.order_detail_value}>{orderInfo.delivery}</span></div>
                            <div className={styles.order_detail_item}><span
                                className={styles.order_detail_name}>Оплата:</span>
                                <span className={styles.order_detail_value}>{orderInfo.payment}</span></div>
                            <div className={styles.order_detail_item}><span
                                className={styles.order_detail_name}>Комментарий:</span> <span
                                className={styles.order_detail_value}>{orderInfo.delivery_note}</span></div>
                            <div className={styles.order_detail_item}><span
                                className={styles.order_detail_name}>Статус заказа:</span>
                                {orderInfo.status === 'active' &&
                                    <span
                                        className={`${styles.order_status} ${styles.order_status_active}`}>В обработке</span>}
                                {orderInfo.status === 'delivered' && <span
                                    className={`${styles.order_status} ${styles.order_status_delivered}`}>Доставлен</span>}
                                {orderInfo.status === 'pending' &&
                                    <span
                                        className={`${styles.order_status} ${styles.order_status_pending}`}>Отменен</span>}
                            </div>
                            {(Date.now() - new Date(orderInfo.date_created).getTime()) / (1000 * 60 * 60) <= 3 &&
                                <div className={styles.order_detail_item}><span className={styles.order_delivery}>Наш менеджер скоро свяжется с вами для уточнения деталей заказа.</span>
                                </div>}
                        </div>
                    </div>

                </div>
            </div>
        );
    } else {
        return (
            <div>
                <img src={gif_loading} alt=""/>
            </div>
        );
    }
}

export default Order;