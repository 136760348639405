import React, {useContext} from 'react';
import {CartContext} from '../../CardContext';
import styles from './cart.module.css'
import './../shared_styles.css'
import EmptyCard from "./empty_card";
import Headers from "./headers";
import CartItem from "./cartItem";

function CartList(props) {
    const {cartItems, clearCart} = useContext(CartContext);
    const totalPrice = cartItems.reduce((acc, item) => acc + item.selected_price * item.quantity, 0);
    return (
        <div>
            <h1>Оформление заказа</h1>
            <div className={styles.divider}></div>
            {cartItems.length === 0 ? (
                <EmptyCard/>
            ) : (
                <div className={styles.cart_conteiner}>
                    <div className={styles.cart_list}>
                        <Headers/>
                        {cartItems.map(item => (
                            <CartItem item={item} key={item.id}/>
                        ))}
                    </div>

                    <div className={styles.float_right_btn}>
                        <div className={styles.empty_cart_return_btn + ' ' + styles.cart_remove_all}
                             onClick={clearCart}>Очитить корзину
                        </div>
                        <div className={styles.total_price}>Итоговая стоимость: <span>{totalPrice} руб.</span></div>

                    </div>

                </div>
            )}

        </div>
    );
}

export default CartList;