import React from 'react';
import styles from './delivery.module.css';
import '../../shared/shared_styles.css'
import {Helmet} from 'react-helmet';
import {ShoppingOutlined, CarOutlined} from '@ant-design/icons';
import {ConfigProvider, Menu} from 'antd';
import DeliveryMinsk from "./menuItems/delivery";
import DeliveryNotMinsk from "./menuItems/deliveryNotMinsk";
import Card from "./menuItems/card";
import Cash from "./menuItems/cash";
import Erip from "./menuItems/erip";
import {useLocation} from "react-router-dom";

function Delivery(props) {
    const [textContent, setTextContent] = React.useState('');
    const onClick = (e) => {
        setTextContent(e.key);
    };
    const items = [
        {
            key: 'sub1',
            label: 'Способы оплаты',
            icon: <ShoppingOutlined/>,
            children: [
                {
                    key: 'g1',
                    label: 'Курьеру',
                    type: 'group',
                    children: [
                        {
                            key: 'cash',
                            label: 'Наличными ',
                        },
                        {
                            key: 'card',
                            label: 'Картой',
                        },
                    ],
                },
                {
                    key: 'g2',
                    label: 'Онлайн',
                    type: 'group',
                    children: [
                        {
                            key: 'Erip',
                            label: 'Ерип',
                        },
                    ],
                }]
        },
        {
            key: 'sub2',
            label: 'Доставка',
            icon: <CarOutlined/>,
            children: [
                {
                    key: 'g4',
                    label: 'Курьером',
                    type: 'group',
                    children: [
                        {
                            key: 'deliveryMinsk',
                            label: 'По Минску',
                        },
                        {
                            key: 'deliveryNotMinsk',
                            label: 'Населенные пункты',
                        },
                    ],
                },
            ]
        }]
    const location = useLocation();
    const canonicalUrl = `https://technomir.by${location.pathname}`
    const renderContent = () => {
        switch (textContent) {
            case 'deliveryMinsk':
                return <DeliveryMinsk/>;
            case 'deliveryNotMinsk':
                return <DeliveryNotMinsk/>;
            case 'cash':
                return <Cash/>;
            case 'card':
                return <Card/>;
            case 'Erip':
                return <Erip/>;
            default:
                return <DeliveryMinsk/>;
        }
    };
    return (
        <div className={styles.delivery + ' common_mg'}>
            <Helmet>
                <link rel="canonical" href={canonicalUrl}/>
                <title>Доствка и Оплата - Магазин Техномир</title>
                <meta
                    name="description"
                    content="В магазине «Техномир» мы предлагаем быструю и надёжную доставку по всей стране и удобные способы оплаты. Оформите заказ прямо сейчас!"
                    />
            </Helmet>
            <ConfigProvider theme={{
                components: {
                    Menu: {
                        horizontalItemHoverColor: '#484c79',
                        horizontalItemSelectedColor: '#484c79',
                        itemSelectedColor: '#484c79',
                    }
                }
            }}>
                <h1>Оплата и доставка</h1>
                <Menu
                    onClick={onClick}
                    defaultSelectedKeys={['1']}
                    mode="horizontal"
                    items={items}
                />
                <div className={styles.delivery_content}>
                    {renderContent()}
                </div>

            </ConfigProvider>
        </div>
    );
}

export default Delivery;