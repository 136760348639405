import React from 'react';
import styles from "./header.module.css";
import Badge from "@mui/material/Badge";
import {Link} from "react-router-dom";
import img_cart_icon from "../static/grocery-store.png";

function CartIcon({cartItems}) {
    return (

            <div className={styles.cart_icon}>
                <Badge badgeContent={cartItems.length} color="secondary">
                    <Link to='/cart'><img src={img_cart_icon} alt=""/></Link>
                </Badge>
            </div>

    );
}

export default CartIcon;
