import React from 'react';
import styles from "../../shared/catalog_component/catalog_component.module.css";
import {Tooltip} from "antd";

function ColorsOption({product, color, setColor, images, setImages, handleChangeMainImg}) {
    const handleColorClick = (clicked_color) => {
        if (clicked_color === color) {
            setColor({'color': ''});
            setImages(product.images)
            handleChangeMainImg(product.images[0])
        } else {
            setColor(clicked_color)
            setImages(clicked_color.imgs)
            handleChangeMainImg(clicked_color.imgs[0])
        }


    };
    return (
        <div>
            <div className={styles.card_colors_container}>
                {
                    product.colors && product.colors.length > 0 &&
                    <div className={styles.card_colors}>
                        <div className={styles.color_header}>Цвет:</div>
                        {product.colors.map((product_color, index) => (
                            <Tooltip placement="top" title={product_color.color}
                                     color={['Белый', 'Серебристый', 'White', 'Awesome White', 'Cream', 'Phantom White'].includes(product_color.color) ? '#939393' : product_color.HEX}
                                     key={index}>
                                <div onClick={() => handleColorClick(product_color)}
                                     style={{backgroundColor: `${product_color.HEX}`}}
                                     className={`${styles.card_color} ${product_color.color === color.color ? styles.selected : ''}`}>

                                </div>
                            </Tooltip>
                        ))}

                    </div>
                }
                {color.color !== '' && <div className={styles.card_color_name}> {color.color}</div>}
            </div>
        </div>
    );
}

export default ColorsOption;