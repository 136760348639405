import styles from "./catalog.module.css";
import CatalogComponent from "../../shared/catalog_component/catalog_component";
import React from "react";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const Catalog = React.memo(({brand, category}) => {
    const location = useLocation();
    const canonicalUrl = `https://technomir.by${location.pathname}`

    return (
        <div className={styles.catalog}>
            <Helmet>
                <link rel="canonical" href={canonicalUrl}/>
                <title></title>
                <meta
                    name="description"
                    content="
                    В нашем каталоге вы найдёте широкий ассортимент электроники и бытовой техники от ведущих мировых брендов. Мы предлагаем только качественные товары по доступным ценам.
                    Ознакомьтесь с ассортиментом магазина Техномир прямо сейчас и выберите то, что вам нужно!"
                />
            </Helmet>
            <CatalogComponent default_brand={brand} default_category={category}/>
        </div>
    );
});

export default Catalog;