import React from 'react';
import '../../shared/shared_styles.css'
import styles from './about.module.css'
import Reviews from "../main/reviews/reviews";
import Advantages from "../main/advantages/advantages";

import Rekviz from "./Rekviz";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const About = React.memo((props) => {
    const location = useLocation();
    const canonicalUrl = `https://technomir.by${location.pathname}`
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={canonicalUrl}/>
                <title>О компании - Магазин Техномир</title>
                <meta
                    name="description"
                    content="В магазине «Техномир» вы найдете широкий ассортимент смартфонов, ноутбуков и гаджетов от ведущих брендов. Лучшие цены и качественное обслуживание!"
                   />
            </Helmet>
            <div className={'common_mg ' + styles.about}>
                <div className={styles.about_header}><h1>О нас</h1></div>
                <div className={styles.about_subHeader}>ООО "Даиманд Техника" работает только с надежными поставщиками,
                    таким образом предлагая своим покупателям:
                </div>
                <ul className={styles.about_list}>
                    <li>максимально широкую линейку продукции.</li>
                    <li>только оригинальную продукцию.</li>
                    <li>гибкую систему рассрочек и кредитования.</li>
                    <li>быструю доставку по всей Беларуси.</li>
                    <li>эксклюзивные предложения и товары.</li>
                </ul>


                <div className={styles.about_text}>
                    <span className={styles.about_subsubHeader}>Откройте новый мир мобильных технологий с нашим магазином ТехноМир!</span>
                    <br/> Мы предлагаем широкий выбор самых
                    передовых смартфонов от ведущих производителей, чтобы вы могли найти именно то устройство, которое
                    вам нужно. Наша миссия - предоставить вам самые лучшие смартфоны по конкурентоспособным ценам и с
                    безупречным сервисом. Наши специалисты всегда готовы помочь вам в выборе идеального устройства, а
                    также ответить на все ваши вопросы. В нашем магазине вы найдете самые последние модели смартфонов,
                    оснащенные самыми передовыми функциями и технологиями. От мощных процессоров и больших объемов
                    оперативной
                    памяти до выдающихся фотокамер и продвинутых систем искусственного интеллекта - мы предлагаем только
                    самое лучшее. <br/> Кроме
                    того, мы предлагаем удобный и безопасный процесс покупки с доставкой прямо к вашей двери. Вы
                    можете быть уверены, что ваша информация всегда защищена, и мы делаем все возможное, чтобы ваша
                    покупка была максимально комфортной и удобной. Станьте частью нашего сообщества и обретите новые
                    возможности с нашими смартфонами от ТехноМир!
                </div>
                <h1>Реквизиты</h1>
                <Rekviz/>

            </div>
            <Reviews/>
            <Advantages/>
        </div>
    );
})

export default About;