import React, { useState, useRef, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import styles from './header.module.css';
import { SMARTPHONES_ENDPOINT } from "../../../ENDPOINTS";
import { useNavigate, useLocation } from "react-router-dom";

const getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
        return [];
    }

    try {
        const response = await fetch(`${SMARTPHONES_ENDPOINT}?search=${inputValue}`);
        const data = await response.json();
        return data.results;
    } catch (error) {
        console.error('Ошибка при поиске предложений:', error);
        return [];
    }
};

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
    <div className={styles.suggestion_block}>
        <div className={styles.suggestion_container}>
            <img className={styles.suggestion_img} src={suggestion.images[0]} alt=""/>
            <div className={styles.suggestion_title}>{suggestion.name}</div>
        </div>
    </div>
);

const theme = {
    container: styles.autosuggestContainer,
    input: styles.input,
    suggestionsContainer: styles.suggestionsContainer,
    suggestionsList: styles.suggestionsList,
    suggestion: styles.suggestion,
    suggestionHighlighted: styles.suggestionHighlighted,
};

const SearchWithAutocomplete = () => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef(null);
    const [searchPerformed, setSearchPerformed] = useState(false);

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = async ({ value }) => {
        const newSuggestions = await getSuggestions(value);
        setSuggestions(newSuggestions);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        navigate(`/catalog?search=${encodeURIComponent(suggestion.name)}`);
        inputRef.current.blur();
        setSearchPerformed(true);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            navigate(`/catalog?search=${encodeURIComponent(value)}`);
            inputRef.current.blur();
            setSearchPerformed(true);
        }
    };

    useEffect(() => {
        if (!searchPerformed) {
            setValue('');
            setSuggestions([]);
        }
        setSearchPerformed(false);
    }, [location]);

    const inputProps = {
        placeholder: 'Поиск...',
        value,
        onChange,
        onKeyPress: handleKeyPress,
        className: styles.customInput,
        ref: inputRef
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={theme}
            onSuggestionSelected={onSuggestionSelected}
        />
    );
};

export default SearchWithAutocomplete;
